import React from 'react'

import LayoutWithLeftNav from '../Shared/Layout/WithLeftNav'
import LayoutNavLink from './NavLink'
import routes from '../../routes'

export const NavAdmin = () => (
  <>
    <LayoutNavLink
      to={routes.admin_user.route.reverse()}
      activeOnRoutes={[routes.admin_user.route]}
    >
      Users
    </LayoutNavLink>
  </>
)

const LayoutAdmin = ({ children }) => {
  return (
    <LayoutWithLeftNav
      navigation={
        <ul>
          <NavAdmin />
        </ul>
      }
    >
      {children}
    </LayoutWithLeftNav>
  )
}

export default LayoutAdmin
